import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import { Button } from "../components/Button"

const CaseStudy = ( {data} ) => {

  const project = data.allProjectsJson.edges;
  const heroimage = getImage(project[0].node.heroimage)
  const insightimage = getImage(project[0].node.insightimage)
  const detailimage1 = getImage(project[0].node.detailimage1)
  const detailimage2 = getImage(project[0].node.detailimage2)
  const resultimage = getImage(project[0].node.resultimage)

  //console.log(project[0].node.title);
  //console.log(image);
  
  //Use the first array item to access prefiltered query like so:
  //console.log(project[0].node.title);
  

  return (
    <Layout>
      <CaseSection>
        <CaseHero>
          <CaseTitles>
            <CaseTag>Case Study</CaseTag>
            <CaseTitle>{project[0].node.title}</CaseTitle>
          </CaseTitles>
          <CaseHeroImage style={{backgroundColor: '#2a2c2b'}}>
            <GatsbyImage id="rotated" image={heroimage} alt="Hero image" style={{opacity: '.1', overflow: 'visible'}}/>
          </CaseHeroImage>
        </CaseHero>
        <Case>
          <Summary>
            <SummaryTitle>Summary</SummaryTitle>
            <SummaryDesc>{project[0].node.summary}</SummaryDesc>
          </Summary>
          <Role>
            <RoleTitle>Role</RoleTitle>
            {project[0].node.role.map((role, index) =>(
            <RoleLabel name={role} key={index}>
              {role}
            </RoleLabel>
          ))}</Role>
        </Case>
        <CaseContent>
          <CaseHeroImage>
            <GatsbyImage image={insightimage} alt="Insight image"/>
          </CaseHeroImage>
          <Insight>
            <InsightTitle>Insight</InsightTitle>
            <InsightText>{project[0].node.insight}</InsightText>
          </Insight>
          <DetailImages>
            <DetailImage1>
              <GatsbyImage image={detailimage1} alt="Detail image"/>
            </DetailImage1>
            <DetailImage2>
              <GatsbyImage image={detailimage2} alt="Detail image"/>
            </DetailImage2>
          </DetailImages>
          <Solution>
            <SolutionTitle>Solution</SolutionTitle>
            <SolutionText>{project[0].node.solution}</SolutionText>
          </Solution>
          <SolutionImages>
            {project[0].node.solutionimages.map((image, index) => {
            const imageArray = [];
            const thisimage = getImage(image.childrenImageSharp[0]);
            imageArray.push(
              <div name={image} index={index}>
                <GatsbyImage image={thisimage} alt="Solution image" key={index} style={{display: 'block'}}/>
              </div>
            )
            //console.log(imageArray);
            return imageArray;
            })} 
          </SolutionImages>
          <Results>
            <ResultsInfo>
              <ResultsTitle>Results</ResultsTitle>
              <ResultsText>{project[0].node.results}</ResultsText>
            </ResultsInfo>
            <ResultsData>
              <Numbers>{project[0].node.numbers}</Numbers>
              <NumbersText>{project[0].node.numberstext}</NumbersText>
            </ResultsData>
          </Results>
          <ResultsImage>
            <GatsbyImage image={resultimage} alt="Result image"/>
          </ResultsImage>
          <CTAContact>
            <DesignColors>
              {project[0].node.colors.map((color, index) =>(
                <ColorBox style={{backgroundColor: color}} key={index} />
              ))}
            </DesignColors>
            <CTAInfo>
              <CTATitle>Need help with your business?</CTATitle>
              <CTAText>Send me a message or call me directly and let’s figure out how we can improve your business.</CTAText>
              <Button to="/#contact" primary="true" fweight="true">Contact me</Button>
            </CTAInfo>
          </CTAContact>
        </CaseContent>
      </CaseSection>
    </Layout>
  )
}

export const query = graphql`
  query caseQuery ( $slug: String ) {
    allProjectsJson (filter: {slug: {eq: $slug} }) {
      edges {
        node {
          slug
          summary
          title
          solution
          role
          results
          numberstext
          numbers
          insight
          colors
          detailimage1 {
            childImageSharp {
              gatsbyImageData
            }
          }
          detailimage2 {
            childImageSharp {
              gatsbyImageData
            }
          }
          heroimage {
            childImageSharp {
              gatsbyImageData
            }
          }
          insightimage {
            childImageSharp {
              gatsbyImageData
            }
          }
          resultimage {
            childImageSharp {
              gatsbyImageData
            }
          }
          solutionimages {
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
  }
}}
`


export default CaseStudy;


const CaseSection = styled.section`
    position: relative;
    
`

const CaseHero = styled.div`
    font-weight: 800;
    font-size: 1.4em;
    color: #2A2C2B;
    
    margin: 0 9.375rem;
    padding: 0rem calc((100vw - 1440px) / 2) 0rem;

    display: grid;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const CaseTag = styled.h2`
    display: inline;
    padding: .5rem 1rem;
    background-color: rgba(255,255,255,0.9);
    font-size: .8em;
    text-transform: uppercase;
`

const CaseTitle = styled.h1`
    width: 80%;
    margin-top: 280px;
    display: block;
    padding: .5rem 1rem;
    background-color: rgba(255,255,255, 0.9);
`

const CaseTitles = styled.div`
    grid-column: 1;
    grid-row: 1;
    padding-top: 2rem;
`

const CaseHeroImage = styled.div`
    display: block;
    grid-column: 1;
    grid-row: 1;
    z-index: -2;

    @media screen and (max-width: 1020px){
      #rotated {
        transform: rotate(25deg);
      }
    }

    @media screen and (max-width: 768px){
      #rotated {
        scale: 1.4;
      }
    }
`

const Case = styled.div`
    font-weight: 400;
    font-size: 1em;
    color: #2A2C2B;
    text-transform: none;
    
    margin: 0 9.375rem;
    padding: 0rem calc((100vw - 1440px) / 2) 0rem;

    display: flex;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const Summary = styled.div`
    width: 70%;
    margin-right: 4em;
    padding: 2rem 2rem 6rem 1rem;

    @media screen and (max-width: 1020px){
    width: 100%;
    }
`

const SummaryTitle = styled.h2`
    text-transform: uppercase;
    margin-bottom: 2rem;
`

const SummaryDesc = styled.div`
    font-size: 1.2em;
`

const Role = styled.div`
    background-color: #2A2C2B;
    color: #FFFFFF;
    width: 30%;
    padding: 2rem;

    @media screen and (max-width: 1020px){
    width: 100%;
    }

`

const RoleTitle = styled.h2`
    text-transform: uppercase;
    margin-bottom: 2rem;
`

const RoleLabel = styled.div`
    font-size: 1.2em;

`

const CaseContent = styled.div`
    font-weight: 400;
    font-size: 1em;
    color: #2A2C2B;
    text-transform: none;
    
    margin: 0 9.375rem;
    padding: 0rem calc((100vw - 1440px) / 2) 0rem;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const Insight = styled.div`
    padding: 4rem 2rem 6rem 1rem;
    margin-right: 4em;

    @media screen and (max-width: 1020px){
      margin-right: 1em;
    }
`

const InsightTitle = styled.h2`
    text-transform: uppercase;
    margin-bottom: 2rem;    
`

const InsightText = styled.div`
    line-height: 2.0;
`

const DetailImages = styled.div`
    display: flex; 

    @media screen and (max-width: 1020px){
      flex-direction: column;
    }
`

const DetailImage1 = styled.div`
    width: 50%;

    @media screen and (max-width: 1020px){
      width: 100%;
    }
`

const DetailImage2 = styled.div`
    width: 50%;

    @media screen and (max-width: 1020px){
      width: 100%;
    }
`

const Solution = styled.div`
    padding: 4rem 2rem 6rem 1rem;
    margin-right: 4em;

    @media screen and (max-width: 1020px){
      margin-right: 1em;
    }
`

const SolutionTitle = styled.h2`
    text-transform: uppercase;
    margin-bottom: 2rem;    
`

const SolutionText = styled.div`
    line-height: 2.0;
`

const SolutionImages = styled.div`
    
`

const Results = styled.div`
    display: flex;

    @media screen and (max-width: 1020px){
      flex-direction: column;
    }
`

const ResultsInfo = styled.div`
    padding: 2em 0 4em 1em;
    width: 70%;
    margin-right: 4em;

    @media screen and (max-width: 1020px){
    width: auto;
    }

    @media screen and (max-width: 768px){
    margin-right: 2em;
    }
`

const ResultsTitle = styled.h2`
    text-transform: uppercase;
    margin-bottom: 2em;
`

const ResultsText = styled.div`
    line-height: 2.0;
`

const ResultsData = styled.div`
    padding: 2em 2em 4em 2em;
    background-color: #0FB680;
    text-align: center;
    width: 30%;

    @media screen and (max-width: 1020px){
    width: 100%;
    }
`

const Numbers = styled.div`
    font-size: 4em;
    font-weight: 600;
`

const NumbersText = styled.div`
    font-size: 1.2em;
    line-height: 2.0;
`

const ResultsImage = styled.div`
    
`

const CTAContact = styled.div`
    margin: 4em 0;
    display: flex;
`

const DesignColors = styled.div`
    
`

const CTAInfo = styled.div`
    padding: 3em 2em;
`

const CTATitle = styled.h2`
    margin-bottom: .4em;
`

const CTAText = styled.div`
    margin-bottom: 3em;
`

const ColorBox = styled.div`
  margin: 1.2rem 1.2rem 1.2rem 0;
  width: 1.6rem;
  height: 1.6rem;
`